<template>
  <v-row class="px-0 sticky-row">
    <v-expansion-panels :value="panels">
      <v-expansion-panel class="px-5" :value="panels">
        <v-expansion-panel-header class="d-flex pa-0">
          <v-col class="pa-0 flex-grow-0" style="flex:0">
            <v-img
              class="rounded-10"
              :style="allowSwap ? 'top: -24px;' : 'top: -15px;'"
              :src="
                `https://firebasestorage.googleapis.com/v0/b/piletivahetus-develop.appspot.com/o/events%2Fjpg%2Fg_e_5h4234h${selectedEvent.globalEventId}h4234h.jpg?alt=media`
              "
              :lazy-src="
                `https://firebasestorage.googleapis.com/v0/b/piletivahetus-develop.appspot.com/o/events%2Fjpg%2Fg_e_5h4234h${selectedEvent.globalEventId}h4234h.jpg?alt=media`
              "
              max-width="65px"
            >
            </v-img>
          </v-col>

          <v-col class="pa-0">
            <h3 class="font-18 primary--text text--darken-3 pl-6 mt-2">
              {{ selectedEvent.title }}
            </h3>
            <div class="pl-6 mt-2">
              Pileteid:
              {{ userAddedTickets.length ? userAddedTickets.length : 0 }}
            </div>
            <div
              v-if="allowSwap"
              class="my-2 primary--text text--darken-3 pl-6"
              style="max-width: 224px;"
            >
              Huvitatud vahetusest
              <br />
              <span class="caption primary--text text--darken-3">{{
                swapDetails
              }}</span>
            </div>
          </v-col>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pb-5" @click.stop="panels = []">
          <div
            v-if="stepperStep > 2"
            class="primary--text text--darken-3 subtitle-2"
          >
            <v-icon class="mr-1 primary--text text--darken-4 subtitle-2" small>
              mdi-map-marker
            </v-icon>
            {{ selectedEvent.venue.split(',')[0] }}
          </div>
          <span
            v-if="stepperStep > 2"
            class="d-flex align-center primary--text text--darken-3 subtitle-2 "
          >
            <v-icon small class="mr-2 primary--text text--darken-4 subtitle-2">
              mdi-calendar-range
            </v-icon>
            {{ fullDateMonth(selectedEvent.startDate) }}
          </span>
          <v-col v-if="userAddedTickets.length" class="pa-0 col-12">
            <div>
              <v-data-table
                :headers="[
                  {
                    text: 'Pilet',
                    align: 'start',
                    sortable: false,
                    value: 'filename'
                  },
                  { text: 'Id', value: 'salesId' },
                  { text: 'Piletitüüp', value: 'ticketType' },
                  { text: 'Istekoht', value: 'seatDetails' },
                  { text: 'Hind', value: 'price' }
                ]"
                :items="userAddedTickets"
                :items-per-page="5"
                class="elevation-0 transparent primary--text text--darken-3 px-0"
                hide-default-footer
                light
                dense
                disable-sort
              ></v-data-table>
            </div>
            <div
              class="primary--text text--darken-3 font-18 text-right pr-12 mt-8 d-flex align-center"
            >
              <v-spacer></v-spacer>
              <span class="mr-4">
                {{
                  userAddedTickets.length && sellAsPatch
                    ? 'Komplekt:'
                    : userAddedTickets.length
                    ? 'Kokku:'
                    : 'Hind'
                }}
              </span>
              <span class="display-1"> {{ getPrice() }}&nbsp;€</span>
            </div>
          </v-col>
          <v-col v-if="stepperStep === 5" class="pa-0 col-12 mt-12">
            <v-row
              class="pa-0"
              :class="{ 'flex-column-reverse': $vuetify.breakpoint.xsOnly }"
            >
              <v-col cols="12" md="6" class="pa-0 pr-0 pr-md-2">
                <v-btn
                  class="d-block mb-4 text-capitalize rounded-10 darken-3 w-100"
                  style="height: 50px"
                  color="primary"
                  @click="setStepperStep(stepperStep - 1)"
                >
                  <v-icon
                    left
                    size="20"
                    class="ma-0"
                    style="position:absolute; left:0px"
                    >mdi-chevron-left</v-icon
                  >
                  {{ $vuetify.lang.t('$vuetify.general.back') }}
                </v-btn>
              </v-col>
              <v-col cols="12" md="6" class="pa-0 pl-0 pl-md-2">
                <v-btn
                  :disabled="!isAuthenticated"
                  class="d-block ml-0 mb-4 text-capitalize rounded-10 w-100"
                  style="height: 50px"
                  color="#ffffff"
                  @click="$emit('addTicket')"
                >
                  Lisa pilet
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import FormatDate from '@/mixins/FormatDate'

export default {
  name: 'TicketSummary',
  mixins: [FormatDate],
  data() {
    return {
      panels: []
    }
  },
  computed: {
    ...mapGetters('events', [
      'selectedEvent',
      'stepperStep',
      'userAddedTickets'
    ]),
    ...mapGetters('authentication', ['isAuthenticated', 'user']),

    ticketSalesId: {
      get() {
        return this.$store.state.events.ticketSalesId
      },
      set(value) {
        this.$store.commit('events/setTicketSalesId', value)
      }
    },
    ticketType: {
      get() {
        return this.$store.state.events.ticketType
      },
      set(value) {
        this.$store.commit('events/setTicketType', value)
      }
    },
    swapDetails: {
      get() {
        return this.$store.state.events.swapDetails
      },
      set(value) {
        this.$store.commit('events/setSwapDetails', value)
      }
    },
    ticketSeat: {
      get() {
        return this.$store.state.events.ticketSeat
      },
      set(value) {
        this.$store.commit('events/setTicketSeat', value)
      }
    },
    selectedPrice: {
      get() {
        return this.$store.state.events.selectedPrice
      },
      set(value) {
        this.$store.commit('events/setSelectedPrice', value)
      }
    },
    allowSwap: {
      get() {
        return this.$store.state.events.allowSwap
      },
      set(value) {
        this.$store.commit('events/setAllowSwap', value)
      }
    },
    sellAsPatch: {
      get() {
        return this.$store.state.events.sellAsPatch
      },
      set(value) {
        this.$store.commit('events/setSellAsPatch', value)
      }
    }
  },
  methods: {
    getPrice() {
      //   let price = 0
      //   if (this.userAddedTickets.length) {
      //     price = this.userAddedTickets
      //       .map(ticket => ticket.price)
      //       .reduce((a, b) => a + b)
      //   } else {
      //     price = this.selectedPrice
      //   }
      //   return price
      // },
      if (this.userAddedTickets.length) {
        if (!this.sellAsPatch) {
          return this.userAddedTickets.reduce((a, b) => {
            return a + b.price
          }, 0)
        }
        return this.selectedPrice
      }
      return 0
    },
    setStepperStep(step) {
      this.$store.commit('events/setStepperStep', step)
    }
  }
}
</script>

<style lang="scss">
.v-expansion-panel-content__wrap {
  padding: 0;
}

.sticky-row {
  position: fixed;
  left: 0px;
  bottom: 0;
  z-index: 2;
  overflow-y: scroll;
  padding-top: 16px !important;
  max-height: 100vh;
}

tr.v-data-table__mobile-table-row td:last-child {
  border-bottom: 1px solid #0184c3 !important;
}
</style>
